import axiosInstance from '../../libs/axios'
import index from '../../@core/http/endpoint/index'

export default {
  // fetchMatriculeListItems({ commit }) {
  //   console.log(axiosInstance.defaults.headers)
  //   return new Promise((resolve, reject) => {
  //     axiosInstance.get(`${index.matricules}`)
  //       .then(response => {
  //         commit('SET_MATRICULES', response.data)

  //         resolve(response)
  //       })
  //       .catch(error => {
  //         console.log(error.code)
  //         reject(error)
  //       })
  //   })
  // },
  // eslint-disable-next-line no-empty-pattern
  registerUser({ }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.signup, item).then(response => {
        console.log(response)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },

  // eslint-disable-next-line no-empty-pattern
  loginUser({ dispatch }, item) {
    console.log(dispatch)
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.login, item).then(response => {
        console.log(response)
        localStorage.setItem('accessToken', response.data.token)
        localStorage.setItem('userData', JSON.stringify(response.data.userData))
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },

  // updateMatriculeItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axiosInstance.put(`${index.matricules}${item.id}/`, item).then(response => {
  //       commit('UPDATE_MATRICULE', response.data)

  //       resolve(response)
  //     })
  //       .catch(error => { reject(error) })
  //   })
  // },
}
