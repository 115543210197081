export default [

  {
    path: '/balises',
    name: 'balises',
    component: () => import('@/views/Balise.vue'),
    meta: {
      // pageTitle: 'Les Categories',
      // breadcrumb: [
      //   {
      //     text: 'Second Page',
      //     active: true,
      //   },
      // ],
      role: ['ADMIN'],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Setting.vue'),
    meta: {
      // pageTitle: 'Les Categories',
      // breadcrumb: [
      //   {
      //     text: 'Second Page',
      //     active: true,
      //   },
      // ],
      role: ['ADMIN'],
    },
  },
  {
    path: '/zones',
    name: 'zones',
    component: () => import('@/views/Zone.vue'),
    meta: {
      // pageTitle: 'Les Categories',
      // breadcrumb: [
      //   {
      //     text: 'Second Page',
      //     active: true,
      //   },
      // ],
      role: ['ADMIN'],
    },
  },
  {
    path: '/departements',
    name: 'departements',
    component: () => import('@/views/Departement.vue'),
    meta: {

      role: ['ADMIN'],
    },
  },
  {
    path: '/communes',
    name: 'communes',
    component: () => import('@/views/Commune.vue'),
    meta: {

      role: ['ADMIN'],
    },
  },
  {
    path: '/arrondissements',
    name: 'arrondissements',
    component: () => import('@/views/Arrondissement.vue'),
    meta: {

      role: ['ADMIN'],
    },
  },
  {
    path: '/recevers',
    name: 'recevers',
    component: () => import('@/views/Recever.vue'),
    meta: {

      role: ['ADMIN', 'POINTFOCAL'],
    },
  },
  {
    path: '/webuser',
    name: 'webuser',
    component: () => import('@/views/WebUser.vue'),
    meta: {
      // pageTitle: 'Les Categories',
      // breadcrumb: [
      //   {
      //     text: 'Second Page',
      //     active: true,
      //   },
      // ],
      role: ['ADMIN'],
    },
  },
  {
    path: '/prealertes',
    name: 'prealertes',
    component: () => import('@/views/Prealert.vue'),
    meta: {
      // pageTitle: 'Les Categories',
      // breadcrumb: [
      //   {
      //     text: 'Second Page',
      //     active: true,
      //   },
      // ],
      role: ['ADMIN', 'ANPC', 'POINTFOCAL'],
    },
  },
  {
    path: '/informations',
    name: 'informations',
    component: () => import('@/views/Informations.vue'),
    meta: {
      // pageTitle: 'Les Categories',
      // breadcrumb: [
      //   {
      //     text: 'Second Page',
      //     active: true,
      //   },
      // ],
      role: ['ADMIN', 'ANPC', 'POINTFOCAL'],
    },
  },
  {
    path: '/detailprealert/:id',
    name: 'detailprealert',
    component: () => import('@/views/DetailPrealert.vue'),
    meta: {
      // pageTitle: 'Les Categories',
      // breadcrumb: [
      //   {
      //     text: 'Second Page',
      //     active: true,
      //   },
      // ],
      role: ['ADMIN', 'ANPC', 'POINTFOCAL'],
    },
  },

]
